import { FetchResult } from "../../types/fetch";

export async function fetchPost<T, U>(
    url: string,
    objToSend: T
): Promise<FetchResult<U>> {
    const response = await fetch(url, {
        method: "POST",
        headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
        },
        credentials: "include",
        body: JSON.stringify(objToSend),
    });
    return response.json();
}

export async function fetchPostFormData<U>(
    url: string,
    form: FormData
): Promise<FetchResult<U>> {
    const response = await fetch(url, {
        method: "POST",
        headers: {
            Accept: "application/json",
        },
        credentials: "include",
        body: form,
    });
    return response.json();
}

export async function fetchPostFormDataBlobResponse(
    url: string,
    form: FormData
): Promise<Blob> {
    const response = await fetch(url, {
        method: "POST",
        headers: {
            Accept: "application/json",
        },
        credentials: "include",
        body: form,
    });
    return response.blob();
}

export async function fetchGet<T extends { [key: string]: unknown }, U>(
    url: string,
    objToSend: T
): Promise<FetchResult<U>> {
    const urlWithParam = `${url}?${Object.keys(objToSend)
        .map(key => `${key}=${objToSend[key]}`)
        .join("&")}`;
    const response = await fetch(urlWithParam, {
        method: "GET",
        headers: {
            Accept: "application/json",
        },
        credentials: "include",
    });
    return response.json();
}
